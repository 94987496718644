import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import SmartImage from 'dpl/components/SmartImage';
import { PHOTO_ALBUM_THUMBS_WIDTH } from 'dpl/constants/photo_album';

export default function PhotoAlbumThumbnailButton({
  isSelected,
  image,
  onClick
}) {
  return (
    <button
      onClick={onClick}
      type="button"
      className={classnames('f0 transition PhotoAlbumThumbnailButton ba bw1', {
        'b--purple': isSelected,
        'o-30 b--transparent': !isSelected
      })}
    >
      <SmartImage
        Tag="div"
        lazy
        crop
        src={image.url}
        width={`${PHOTO_ALBUM_THUMBS_WIDTH - 4}px`}
        height={`${PHOTO_ALBUM_THUMBS_WIDTH - 4}px`}
        className="cover bg-center"
        loadingClass="bg-light-gray"
      />
    </button>
  );
}

PhotoAlbumThumbnailButton.propTypes = {
  isSelected: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  image: PropTypes.shape({
    url: PropTypes.string.isRequired
  }).isRequired
};

PhotoAlbumThumbnailButton.defaultProps = {
  isSelected: false
};
