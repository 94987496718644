import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import ClampedMultiLineText from 'dpl/common/components/ClampedMultiLineText';
import Icon from 'dpl/common/components/Icon';
import SmartImage from 'dpl/components/SmartImage';
import { isGreaterThanBreakpoint } from 'dpl/util/grid';
import MoreImagesCount from 'dpl/testimonials/components/MoreImagesCount';
import { noop } from 'dpl/shared/utils';

function Thumbnail({ onClick, url, width, height }) {
  const img = (
    <SmartImage
      Tag="div"
      src={url}
      className="bg-center cover br3"
      width={width}
      height={height}
    />
  );

  if (onClick) {
    return (
      <button type="button" onClick={onClick}>
        {img}
      </button>
    );
  }

  return img;
}
export default function Testimonial({
  text,
  photographs,
  dogName,
  dogAge,
  instagramHandle,
  clampText,
  onShowMoreClick,
  onImageClick
}) {
  const isGreaterThanSmall = isGreaterThanBreakpoint('sm');
  const maxPhotosShown = 2;
  const thumbnailWidth = isGreaterThanSmall ? '199px' : '165px';
  const thumbnailHeight = isGreaterThanSmall ? '120px' : '100px';

  const countPhotosNotShown = Math.max(photographs.length - maxPhotosShown, 0);

  return (
    <div className="Testimonial">
      <div className="mb2">
        {clampText ? (
          <ClampedMultiLineText
            text={text}
            numOfLines={5}
            lineHeight={24}
            displayMoreText={!onShowMoreClick}
            className="font-16"
          />
        ) : (
          <p>{text}</p>
        )}
      </div>
      {onShowMoreClick && (
        <button
          type="button"
          onClick={onShowMoreClick}
          className="fw-medium inline-flex mt2 align-items-center"
        >
          <span className="underline midnight font-16">Show more</span>
        </button>
      )}
      <div className="mt6 mt4-sm">
        <div className="max-content relative">
          {!!photographs.length && (
            <div className="flex f0 mb2">
              {photographs.slice(0, maxPhotosShown).map((photo, idx) => (
                <div
                  key={photo.id}
                  className={classnames('overflow-hidden br3', {
                    ml1: idx !== 0
                  })}
                >
                  <Thumbnail
                    onClick={onImageClick ? () => onImageClick(photo.id) : null}
                    url={photo.url_small || photo.url}
                    width={thumbnailWidth}
                    height={thumbnailHeight}
                  />
                </div>
              ))}
            </div>
          )}
          {!!countPhotosNotShown && (
            <div className="absolute right-0 bottom-0 mr2 mb2">
              <MoreImagesCount count={countPhotosNotShown} />
            </div>
          )}
        </div>
        <p className="i font-14">
          <span>{dogName}</span>
          {dogAge && <span>, {dogAge}</span>}
        </p>
      </div>
      {instagramHandle && (
        <div className="mt6 mt4-sm">
          <a
            href={`https://www.instagram.com/${instagramHandle}/`}
            rel="noopener noreferrer"
            target="_blank"
            className="button purple hover-bg-lightest-purple inline-flex fw-medium w-100 w-auto-ns justify-center align-items-center f2"
          >
            <Icon className="mr2" name="instagram" width="20px" height="20px" />
            <span>{instagramHandle}</span>
          </a>
        </div>
      )}
    </div>
  );
}

Testimonial.propTypes = {
  text: PropTypes.string.isRequired,
  photographs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      url: PropTypes.string.isRequired
    })
  ),
  dogName: PropTypes.string.isRequired,
  dogAge: PropTypes.string,
  instagramHandle: PropTypes.string,
  clampText: PropTypes.bool,
  onShowMoreClick: PropTypes.func,
  onImageClick: PropTypes.func
};

Testimonial.defaultProps = {
  photographs: [],
  instagramHandle: null,
  dogAge: '',
  clampText: false,
  onShowMoreClick: null,
  onImageClick: noop
};
